import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="User_Contained_Outline"
      data-name="User Contained Outline"
      className="cls-1"
      d="M40,80c39.691,0,40-17.909,40-40S79.691,0,40,0,0,17.909,0,40,0.309,80,40,80ZM62.991,62H59.258a3,3,0,0,1-2.5-1.336L52.986,55H27.038l-3.776,5.664A3,3,0,0,1,20.766,62H17.033a1,1,0,0,1-.832-1.554l6.734-10.11A3,3,0,0,1,25.43,49H54.594a3.179,3.179,0,0,1,2.494,1.336l6.734,10.11A1,1,0,0,1,62.991,62ZM40,44c-9.719,0-15-5.281-15-15s5.281-15,15-15,15,5.282,15,15S49.719,44,40,44Zm0-24c-6.448,0-9,2.552-9,9s2.552,9,9,9,9-2.552,9-9S46.448,20,40,20Z"
    />
  </svg>
))
