import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Globe"
      className="cls-1"
      d="M40,80c39.691,0,40-17.909,40-40S79.691,0,40,0,0,17.909,0,40,0.309,80,40,80Zm0-16c-14.578,0-24-9.422-24-24s9.422-24,24-24,24,9.422,24,24S54.578,64,40,64ZM56.118,49H50.41a34.243,34.243,0,0,1-1.8,7.331A14.754,14.754,0,0,0,56.118,49ZM40,58c1.434,0,3.352-2.911,4.344-9H35.656C36.648,55.089,38.566,58,40,58Zm-8.611-1.669A34.25,34.25,0,0,1,29.59,49H23.882A14.754,14.754,0,0,0,31.389,56.331ZM22.177,37a25.469,25.469,0,0,0,0,6h6.888C29.026,42.026,29,41.033,29,40s0.026-2.026.065-3H22.177Zm1.7-6H29.59a34.247,34.247,0,0,1,1.8-7.331A14.753,14.753,0,0,0,23.882,31ZM40,22c-1.434,0-3.352,2.911-4.344,9h8.687C43.352,24.911,41.434,22,40,22Zm4.929,15H35.071C35.029,37.958,35,38.946,35,40s0.029,2.042.071,3h9.859c0.041-.958.071-1.946,0.071-3S44.971,37.958,44.929,37Zm3.682-13.331A34.247,34.247,0,0,1,50.41,31h5.708A14.753,14.753,0,0,0,48.612,23.669ZM57.823,37H50.935C50.974,37.974,51,38.967,51,40s-0.026,2.026-.065,3h6.887A25.483,25.483,0,0,0,57.823,37Z"
    />
  </svg>
))
