import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Locked_Filled"
      data-name="Locked Filled"
      className="cls-1"
      d="M40,80c39.691,0,40-17.909,40-40S79.691,0,40,0,0,17.909,0,40,0.309,80,40,80ZM52.091,61H27.909A6.975,6.975,0,0,1,21,53.96V37.68a1.308,1.308,0,0,1,1.3-1.32h3.886V31.08C26.182,22.528,31.607,17,40,17s13.818,5.528,13.818,14.08v5.28H57.7A1.308,1.308,0,0,1,59,37.68V53.96A6.975,6.975,0,0,1,52.091,61ZM48.636,31.08c0-5.674-3.068-8.8-8.636-8.8s-8.636,3.126-8.636,8.8v5.28H48.636V31.08Z"
    />
  </svg>
))
