import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 200 210"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="O"
      className="cls-1"
      fillRule="evenodd"
      d="M200,107.349a75.844,75.844,0,0,1-33.429,62.971l-52.7,35.422a24.832,24.832,0,0,1-27.735,0l-52.7-35.421A75.848,75.848,0,0,1,0,107.349V25.183A25.1,25.1,0,0,1,25-.021H175a25.1,25.1,0,0,1,25,25.2v82.166Zm-89.868,25.927c-9.422-5.24-15.26-15.47-15.26-28.34V83.481c0-13.068-8.02-21.456-20.513-21.456S53.846,70.414,53.846,83.481v20.784c0,24.909,15.288,40.9,39.1,40.9a43.484,43.484,0,0,0,16.739-3.126C114.541,140.025,114.741,135.84,110.132,133.276Zm36.022-49.125v20.785c0,13.067-8.02,21.455-20.513,21.455S105.128,118,105.128,104.936V83.481c0-12.87-5.838-23.1-15.26-28.341-4.609-2.564-4.408-6.748.444-8.762a43.48,43.48,0,0,1,16.739-3.126C130.866,43.252,146.154,59.242,146.154,84.151Z"
    />
  </svg>
))
