import { graphql } from "gatsby"
import React, { FC, Fragment } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import * as styles from "./styles.module.scss"
import { IconProps } from "src/components/Update/types"
import Identity from "src/components/Icons/Indentity-management"
import Data from "src/components/Icons/Data-protection"
import Information from "src/components/Icons/Information-governance"
import Security from "src/components/Icons/Security"
import Logo from "src/images/structured-data-logo.png"
import GetStarted from "src/components/Banners/GetStarted"

const Icon: FC<IconProps> = ({ icon }) => {
  // noinspection JSRedundantSwitchStatement
  switch (icon) {
    case "identity":
      return <Identity className={styles.icon} aria-hidden="true" />
    case "data":
      return <Data className={styles.icon} aria-hidden="true" />
    default:
      return <Information className={styles.icon} aria-hidden="true" />
  }
}

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <div className={`content-wrapper ${styles.security}`}>
        <Security className={styles.iconSecurity} />
        <span className={styles.label}>{texts.label}</span>
        <h1 className={styles.title}>{texts.title}</h1>
        <p className={styles.description}>{texts.description}</p>
        {texts.points.map((point: any) => (
          <Fragment key={point.name}>
            <Icon icon={point.icon} />
            <h2 className={styles.name}>{point.name}</h2>
            <p className={`${styles.description} ${styles.point}`}>
              {point.description}
            </p>
          </Fragment>
        ))}
      </div>
      <div className={`content-wrapper ${styles.banner}`}>
        <GetStarted />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        label
        title
        description
        points {
          name
          description
          icon
        }
      }
    }
  }
`

export default Template
